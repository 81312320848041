import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuid } from "uuid";

import agent from "../api/agent";
import { CompetenceLevel } from "../models/competenceLevel";
import { LearningGoalStatement } from "../models/LearningGoalStatement";
import { NodeData } from "../models/nodeData";
import { NodeType } from "../models/nodeType";

export default class NodeStore {
  nodes: NodeData[] | null = null;

  selectedNode: NodeData | null = null;

  loading = false;
  editMode = false;

  nodeTypes: NodeType[] = [];

  competenceLevels: CompetenceLevel[] = [];

  selectedLearningGoalStatement: LearningGoalStatement | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  loadNodes = async (searchString: string) => {
    this.setLoading(true);
    this.nodes = null;

    try {
      const nodes = await agent.Nodes.list(searchString);
      if (nodes && nodes.length > 0) {
        runInAction(async () => {
          this.nodes = nodes;
        });
      }
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false);
  };

  loadNode = async (id: string) => {
    this.setLoading(true);
    try {
      const node = await agent.Nodes.get(id);
      if (node) {
        runInAction(async () => {
          this.setSelectedNode(node);
        });
      }
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false);
  };

  loadNodeTypes = async () => {
    try {
      const nodeTypes = await agent.Nodes.listTypes();
      if (nodeTypes) {
        runInAction(async () => {
          this.nodeTypes = nodeTypes;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  loadCompetenceLevels = async () => {
    try {
      const competenceLevels = await agent.Nodes.listCompetenceLevels();
      if (competenceLevels) {
        runInAction(async () => {
          this.competenceLevels = competenceLevels;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  clearNodes = () => {
    runInAction(() => {
      this.nodes = null;
    });
  };

  initiateNewNode = () => {
    const newNode = new NodeData({
      id: uuid(),
      title: "",
      shortDescription: "",
      richDescription: "",
      type: "",
      organizationTitle: "", //TODO: add organization in backend from user manager
      organizationId: "",
      isDeleted: false,
      boards: [],
      edges: [],
      learningGoals: [],
      altTerms: "",
    });
    return newNode;
  };

  createNode = async (newNode: NodeData) => {
    this.setLoading(true);

    try {
      const newNodeFromBE = await agent.Nodes.create(newNode);
      runInAction(async () => {
        this.setSelectedNode(newNodeFromBE);
      });
    } catch (error) {
      console.error(error);
    }

    this.setLoading(false);
  };

  deleteNode = async (nodeId: string) => {
    this.setLoading(true);
    await agent.Nodes.delete(nodeId);
    this.clearSelectedNode();
    this.clearNodes();
    this.setLoading(false);
  };

  updateBaseData = async (node: NodeData) => {
    await agent.Nodes.updateBaseData(node);
    runInAction(async () => {
      this.setSelectedNode(node);
    });
  };

  updateRichDescription = (nodeId: string, description: string) => {
    this.setLoading(true);
    agent.Nodes.updateRichDescription(nodeId, description);
    this.setSelectedNodesRichDescription(description);
    this.setLoading(false);
  };

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  setSelectedNode = (node: NodeData) => {
    runInAction(() => {
      if (node) this.selectedNode = node;
      else this.selectedNode = null;
    });
  };

  setSelectedNodeById = (id: string) => {
    runInAction(() => {
      const node = this.nodes?.find((n) => {
        return n.id === id;
      });

      if (node) this.selectedNode = node;
      else this.selectedNode = null;
    });
  };

  setSelectedNodesRichDescription = (content: string) => {
    runInAction(() => {
      if (content && this.selectedNode) {
        this.selectedNode.richDescription = content;
      }
    });
  };

  clearSelectedNode = () => {
    runInAction(() => {
      this.selectedNode = null;
    });
  };

  getNodesEdges = async (nodeId: string) => {
    const edges = await agent.Nodes.listEdges(nodeId);
    if (edges) return edges;
    else return [];
  };

  addLearningStatement = async (learningGoalId: string, text: string) => {
    try {
      const goal = this.selectedNode?.learningGoals.find(
        (lg) => lg.id === learningGoalId
      );
      if (!goal) throw new Error("Learning Goal not found in clients data");

      const newStatement: LearningGoalStatement = {
        id: uuid(),
        content: text,
        learningGoalId: learningGoalId,
      };

      await agent.LearningGoalStatements.create(newStatement);

      runInAction(async () => {
        goal.learningGoalStatements.push(newStatement);
      });
    } catch (error) {
      console.error(error);
    }
  };

  updateLearningGoalStatement = async (
    statement: LearningGoalStatement,
    text: string
  ) => {
    try {
      const learningGoal = this.selectedNode?.learningGoals.find((lg) =>
        lg.learningGoalStatements.some((s) => s.id === statement.id)
      );
      if (!learningGoal)
        throw new Error("Learning goal not found in client data");

      const editedStatement: LearningGoalStatement = {
        ...statement,
        content: text,
      };
      await agent.LearningGoalStatements.update(editedStatement);

      runInAction(async () => {
        learningGoal.learningGoalStatements =
          learningGoal.learningGoalStatements.map((lgs) => {
            if (lgs.id === statement.id) return editedStatement;
            else return lgs;
          });
      });
    } catch (error) {
      console.error(error);
    }
  };

  deleteLearningGoalStatement = async (statementId: string) => {
    try {
      const learningGoal = this.selectedNode?.learningGoals.find((lg) =>
        lg.learningGoalStatements.some((s) => s.id === statementId)
      );
      if (!learningGoal)
        throw new Error("Learning Goal not found in clients data");

      await agent.LearningGoalStatements.delete(statementId);
      runInAction(async () => {
        learningGoal.learningGoalStatements =
          learningGoal?.learningGoalStatements.filter(
            (s) => s.id !== statementId
          );
      });
    } catch (error) {
      console.error(error);
    }
  };

  saveAltTerms = async (node: NodeData, newAltTerms: string) => {
    const priorTerms = node.altTerms;
    try {
      runInAction(async () => {
        node.altTerms = newAltTerms;
      });
      agent.Nodes.updateAltTerms(node.id, newAltTerms);
    } catch (error) {
      runInAction(async () => {
        node.altTerms = priorTerms;
      });
      console.error(error);
    }
  };
}
