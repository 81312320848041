import { makeAutoObservable, runInAction } from "mobx";

import { Edge, Node } from "reactflow";

import { EdgeData } from "../models/edgeData";
import { GraphData, GraphNodeData } from "../models/graphData";

import { CustomNodeData } from "../../features/graph/customs/CustomNode";
import agent from "../api/agent";
import { EdgeType } from "../models/edgeType";

export default class GraphStore {
  graphData: {
    nodes: GraphNodeData[];
    edges: EdgeData[];
  } = {
    nodes: [],
    edges: [],
  };

  selectedNode: GraphNodeData | null = null;
  selectedEdge: Edge<EdgeData> | null = null;

  edgeTypes: EdgeType[] = [];

  loading = false;
  editMode = false;
  showId = false;
  isDirty = false;

  edgeIdsToBeDeleted: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setIsDirty(value: boolean) {
    this.isDirty = value;
  }

  setShowId(value: boolean) {
    this.showId = value;
  }

  loadGraphData = async (boardId: string) => {
    this.setLoading(true);
    this.graphData = { nodes: [], edges: [] };

    try {
      const graphData = await agent.Graphs.getBoardsStaticGraph(boardId);
      runInAction(async () => {
        this.graphData = graphData;
      });
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false);
  };

  loadEdgeTypes = async () => {
    try {
      const edgeTypes = await agent.Graphs.listEdgeTypes();
      if (edgeTypes) {
        runInAction(async () => {
          this.edgeTypes = edgeTypes;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  setLoading(state: boolean) {
    runInAction(() => {
      this.loading = state;
    });
  }

  setSelectedNode(node: GraphNodeData | null) {
    this.selectedNode = node;
  }

  setSelectedEdge(edge: Edge<EdgeData> | null) {
    this.selectedEdge = edge;
  }

  resetEdgeIdsToBeDeleted = () => {
    this.edgeIdsToBeDeleted = [];
  };

  addEdgeIdToBeDeleted = (edgeId: string) => {
    this.edgeIdsToBeDeleted = [...this.edgeIdsToBeDeleted, edgeId];
  };

  saveGraphDataFromStaticReactFlow = async (
    rfNodes: Node[],
    rfEdges: Edge[],
    deletedEdgeIds: string[],
    boardId: string
  ) => {
    const data = this.transformFromReactFlowDataToGraphData(rfNodes, rfEdges);
    await agent.Graphs.saveBoardsGraph(boardId, data, deletedEdgeIds);
    await this.loadGraphData(boardId);
  };

  transformFromReactFlowDataToGraphData = (
    rfNodes: Node[],
    rfEdges: Edge[]
  ): GraphData => {
    var data: GraphData = { nodes: [], edges: [] };

    data.nodes = rfNodes.map((node) => {
      return {
        id: node.data.boardNodeId,
        nodeId: node.id,
        title: node.data.label,
        description: node.data.description,
        type: node.data.type,
        position: node.position,
        edges: node.data.edges,
      };
    });

    data.edges = rfEdges.map((edge) => {
      return {
        id: edge.id,
        sourceNodeId: edge.source,
        targetNodeId: edge.target,
        type: edge.label!.toString(),
      };
    });

    return data;
  };

  get getNodesForStaticReactFlow() {
    var boardNodes: Node<CustomNodeData>[] = [];

    boardNodes = this.graphData.nodes.map((boardNode) => ({
      id: boardNode.nodeId, //send the nodeId so the absolute edges can be managed directly without the need to map boardNodeIds and NodeIds
      position: boardNode.position,
      data: {
        label: boardNode.title,
        description: boardNode.description,
        boardNodeId: boardNode.id,
        type: boardNode.type,
        edges: boardNode.edges,
      },
    }));

    return boardNodes;
  }

  get getEdgesForStaticReactFlow() {
    var edges: Edge<EdgeData>[] = [];

    edges = this.graphData.edges.map((edge) => ({
      id: edge.id,
      source: edge.sourceNodeId,
      target: edge.targetNodeId,
      label: edge.type,
      className: "normal-edge",
      data: edge,
    }));

    return edges;
  }

  getNodeTitle = (id: string) => {
    if (!this.graphData || !this.graphData.nodes)
      return "Keine Kompetenzbasen vorhanden";
    var nodes = this.graphData.nodes as GraphNodeData[];
    var filteredNodes = nodes.filter((n) => n.nodeId === id);
    if (filteredNodes.length === 0) return "Keine Kompetenzbasen gefunden";
    var title = filteredNodes[0].title;
    if (!title || title.length === 0) return "Kein Titel";

    return title;
  };
}
