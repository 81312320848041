import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Popover,
  Typography,
} from "@mui/material";

import {
  AnalyticsOutlined,
  ArrowBackIosNew,
  Close,
  DeleteOutline,
  DownloadOutlined,
  DriveFileRenameOutline,
  ErrorOutline,
  InfoOutlined,
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
  LockOutlined,
  RestoreOutlined,
  SaveOutlined,
} from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import ConfirmModal from "../../app/common/modals/ConfirmModal";
import { router } from "../../app/router/Routes";
import { useStore } from "../../app/stores/store";
import StaticReactFlowEditor from "./StaticReactFlowEditor";
import GraphDetailsModal from "./details/GraphDetailsModal";

export default observer(function GraphEditorScreen() {
  const { id } = useParams();
  const { boardStore, nodeStore, graphStore, modalStore } = useStore();
  const { selectedBoard } = boardStore;

  useEffect(() => {
    if (id) {
      boardStore.clearSelectedBoard();
      boardStore.loadBoard(id);
    }

    nodeStore.loadNodeTypes();
    graphStore.loadEdgeTypes();
  }, [nodeStore, graphStore, id, boardStore]);

  const handleBoardDelete = async () => {
    if (!selectedBoard) return;
    await boardStore.deleteBoard(selectedBoard.id);
    modalStore.closeModal();
    await router.navigate("/boards");
  };

  return (
    <Box height={"100%"}>
      <Grid container direction="column">
        <Grid
          item
          container
          xs={12}
          borderBottom={1}
          borderColor="#E0E0E0"
          paddingY={2}
          paddingX={2}
        >
          <Grid
            item
            xs={3}
            justifyContent="start"
            alignItems="center"
            display="flex"
          >
            <Button startIcon={<ArrowBackIosNew />} color="inherit">
              <Typography>Zurück</Typography>
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            justifyContent="center"
            alignItems="center"
            display="flex"
            px={4}
          >
            <GraphActionsDropdown
              items={[
                {
                  icon: <InfoOutlined htmlColor="#000" />,
                  label: "Eigenschaften",
                  onClick: () =>
                    modalStore.openModal(<GraphDetailsModal />, "small", {
                      padding: "0px 20px"
                    }),
                },
                {
                  icon: <RestoreOutlined htmlColor="#000" />,
                  label: "Log Files",
                },
                {
                  icon: <AnalyticsOutlined htmlColor="#000" />,
                  label: "Analytics",
                },
                undefined,
                {
                  icon: <DriveFileRenameOutline htmlColor="#000" />,
                  label: "Unbenennen",
                },
                {
                  icon: <DownloadOutlined htmlColor="#000" />,
                  label: "Download",
                },
                {
                  icon: <SaveOutlined htmlColor="#000" />,
                  label: "Speichern",
                },
                {
                  icon: <DeleteOutline htmlColor="#ff0000" />,
                  label: "Board löschen",
                  color: "#ff0000",
                  onClick: () =>
                    modalStore.openModal(
                      <ConfirmModal
                        title="Board löschen"
                        text="Board wirklich löschen?"
                        onConfirm={() => handleBoardDelete()}
                        onCancel={() => {
                          modalStore.closeModal();
                        }}
                      />,
                      "mini"
                    ),
                },
              ]}
            />
          </Grid>

          <Grid
            item
            xs={3}
            justifyContent="end"
            alignItems="center"
            display="flex"
            spacing={2}
          >
            {graphStore.isDirty && (
              <Chip
                sx={{
                  padding: 1,
                }}
                label={<Typography>nicht gespeichert</Typography>}
                icon={<ErrorOutline />}
              />
            )}

            {boardStore.selectedBoard?.readonly && (
              <Chip
                sx={{
                  padding: 1,
                }}
                color="primary"
                label={<Typography>Nur zur Ansicht</Typography>}
                icon={<LockOutlined />}
              />
            )}

            <IconButton onClick={() => router.navigate("/boards")}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container>
          <Grid item xs={12}>
            <Container
              sx={{ height: "calc(100vh - 100px)", width: "100%" }}
              maxWidth={false}
            >
              <StaticReactFlowEditor />
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

const GraphActionsDropdown = observer(
  (props: {
    items: Array<
      | {
          label: string;
          icon?: React.ReactNode;
          color?: string;
          onClick?: () => void;
        }
      | undefined
    >;
  }) => {
    const { boardStore } = useStore();
    const [isOpen, setIsOpen] = useState(false);
    const containerRef = useRef<HTMLDivElement>();

    return (
      <>
        <Box
          ref={containerRef}
          display="flex"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="h5" align="center" noWrap>
            {boardStore.loading
              ? "Loading"
              : boardStore.selectedBoard?.title || "Kein Board ausgewählt"}
          </Typography>

          {boardStore.selectedBoard && (
            <IconButton onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? (
                <KeyboardArrowUpOutlined />
              ) : (
                <KeyboardArrowDownOutlined />
              )}
            </IconButton>
          )}

          <Popover
            open={isOpen}
            anchorEl={containerRef.current}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onBlur={() => setIsOpen(false)}
            PaperProps={{
              style: {
                borderRadius: "20px",
                transform: "translate(-50%,20px)",
                minWidth: "200px",
                maxWidth: "400px",
              },
            }}
          >
            <Card>
              <CardContent>
                {props.items.map((item, index) =>
                  item ? (
                    <Button
                      key={`graph_actions_${index}`}
                      startIcon={item.icon}
                      fullWidth
                      sx={{
                        justifyContent: "start",
                        p: 2,
                        borderRadius: "999px",
                      }}
                      onClick={item.onClick}
                      size="small"
                    >
                      <Typography
                        fontWeight={600}
                        color={item.color || "text.primary"}
                      >
                        {item.label}
                      </Typography>
                    </Button>
                  ) : (
                    <Divider sx={{ my: 2 }} />
                  )
                )}
              </CardContent>
            </Card>
          </Popover>
        </Box>
      </>
    );
  }
);
