import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  Add,
  ClearOutlined,
  Edit,
  Save,
  Search,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import CircleIcon from "@mui/icons-material/Circle";
import { observer } from "mobx-react-lite";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Node, useReactFlow, XYPosition } from "reactflow";
import { useDebounce } from "../../app/common/UtilHooks";
import { NodeData, NodeType } from "../../app/models/nodeData";
import { useStore } from "../../app/stores/store";
import { NodeLabelIconData } from "../nodes/NodeCardLabelIcon";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NodeEdgeInfo } from "../../app/models/nodeEdgeInfo";
import AddNodeModal from "./AddNodeModal";
import DownloadButton from "./DownloadButton";
import { CustomNodeData } from "./customs/CustomNode";

export default observer(function GraphEditorTools({
  hidden,
  onSave,
  handleAddBoardNode,
}: {
  hidden?: boolean;
  onSave: Function;
  handleAddBoardNode: (
    nodeId: string | null,
    title: string,
    shortDescr: string,
    nodeType: string,
    position: XYPosition,
    edges: NodeEdgeInfo[]
  ) => void;
}) {
  const [open, setOpen] = useState(false);
  const { graphStore, boardStore } = useStore();
  const { selectedBoard } = boardStore;

  return (
    <Box
      display={hidden ? "none" : "block"}
      position="absolute"
      top={20}
      right={20}
      minWidth={400}
      maxWidth={500}
      width="30%"
    >
      <Card
        sx={{
          height: "100%",
          width: "100%",
          borderRadius: "20px",
          border: "1px solid #D0D0D0",
          overflow: "auto",
        }}
        variant="outlined"
      >
        <CardContent
          sx={{
            padding: "10px !important",
          }}
        >
          <Box position="relative">
            <Box marginX="auto" width="fit-content">
              <IconButton
                sx={{
                  border: 2,
                  borderColor: "#E0E0E0",
                }}
                size="large"
                onClick={() => setOpen(!open)}
                focusRipple={false}
              >
                <Edit />
              </IconButton>
            </Box>

            <Box width="fit-content" position="absolute" top={0} left={10}>
              <IconButton
                sx={{
                  border: 2,
                  borderColor: "#E0E0E0",
                }}
                size="large"
                onClick={() => graphStore.setShowId(!graphStore.showId)}
                focusRipple={false}
              >
                {graphStore.showId ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </Box>

            <Box width="fit-content" position="absolute" top={0} right={70}>
              <DownloadButton />
            </Box>

            <Box width="fit-content" position="absolute" top={0} right={10}>
              <IconButton
                sx={{
                  border: 2,
                  borderColor: "#E0E0E0",
                }}
                size="large"
                onClick={() => onSave()}
                disabled={!graphStore.isDirty || selectedBoard?.readonly}
                focusRipple={false}
              >
                <Save />
              </IconButton>
            </Box>
          </Box>
        </CardContent>

        {open && (
          <>
            <Divider />

            <CardContent
              sx={{
                padding: 0,
                background: "#F9F9F9",
                overflow: "auto",
                maxHeight: "90vh",
              }}
            >
              <GraphToolsContent handleAddBoardNode={handleAddBoardNode} />
            </CardContent>
          </>
        )}
      </Card>
    </Box>
  );
});

const GraphToolsContent = observer(
  ({
    handleAddBoardNode,
  }: {
    handleAddBoardNode: (
      nodeId: string | null,
      title: string,
      shortDescr: string,
      nodeType: string,
      position: XYPosition,
      edges: NodeEdgeInfo[]
    ) => void;
  }) => {
    const reactflow = useReactFlow();
    const { nodeStore, boardStore, modalStore } = useStore();
    const { loadNodes, clearNodes } = nodeStore;
    const { selectedBoard } = boardStore;
    const [searchValue, setSearchValue] = useState("");
    const deboundedSearchValue = useDebounce(searchValue, 500, true);

    const graphNodes = reactflow
      .getNodes()
      .map((node) => node as Node<CustomNodeData>);

    const searchResultInGraph = useMemo(() => {
      const text = deboundedSearchValue.toLowerCase().trim();

      if (text.length === 0) return [];

      return graphNodes.filter((node) =>
        node.data.label.toLowerCase().includes(text)
      );
    }, [deboundedSearchValue, graphNodes]);

    const searchResultOutGraph = useMemo(() => {
      return (
        nodeStore.nodes?.filter(
          (node) => !searchResultInGraph.find((n) => n.id === node.id)
        ) ?? []
      );
    }, [nodeStore.nodes, searchResultInGraph]);

    useEffect(() => {
      if (deboundedSearchValue.length > 3) {
        loadNodes(deboundedSearchValue);
      } else {
        clearNodes();
      }
    }, [clearNodes, deboundedSearchValue, loadNodes]);

    const onClickNewNodeButton = useCallback(() => {
      modalStore.openModal(
        <AddNodeModal
          addNode={handleAddBoardNode}
          position={reactflow.getViewport()}
          graphNodes={reactflow.getNodes()}
        />,
        "large"
      );
    }, [handleAddBoardNode, modalStore, reactflow]);

    return (
      <>
        <Box paddingX={4}>
          <Box paddingY={4}>
            <SearchInputComponent
              value={searchValue}
              setValue={setSearchValue}
            />
          </Box>

          {!selectedBoard?.readonly &&
          deboundedSearchValue.length > 0 &&
          searchResultInGraph.length === 0 &&
          searchResultOutGraph.length === 0 ? (
            <CreateNodeSection
              title={searchValue}
              onCancel={() => {
                setSearchValue("");
              }}
            />
          ) : (
            <>
              <NodeResultSection
                inGraph={searchResultInGraph}
                outGraph={searchResultOutGraph}
              />

              <Divider>
                <Typography fontWeight={600}>Oder</Typography>
              </Divider>

              <Box paddingX={4} paddingTop={2}>
                <Tooltip title="Auf das Board ziehen">
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{
                      paddingY: 2,
                    }}
                    onClick={onClickNewNodeButton}
                    disabled={selectedBoard?.readonly}
                  >
                    <Typography
                      fontWeight={600}
                      color={selectedBoard?.readonly ? "inherit" : "primary"}
                    >
                      Neuen Kompetenzpunkt erstellen
                    </Typography>
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </>
    );
  }
);

const CreateNodeSection = observer(
  ({ onCancel, title }: { onCancel: () => void; title: string }) => {
    const { nodeStore } = useStore();
    const [nodeType, setNodeType] = useState(NodeType.Concept);
    const [desc, setDesc] = useState("");

    const [createdNodeData, setCreatedNodeData] = useState<
      NodeData | undefined
    >();

    useEffect(() => {
      setCreatedNodeData(undefined);
    }, [title]);

    const handleCreateNode = useCallback(async () => {
      const newNode = nodeStore.initiateNewNode();

      newNode.title = title;
      newNode.shortDescription = desc;
      newNode.type = nodeType;

      try {
        await nodeStore.createNode(newNode);
        setCreatedNodeData(newNode);
      } catch (error) {
        console.error(error);
      }
    }, [desc, nodeStore, nodeType, title]);

    const onDragStart = (event: any, node: NodeData) => {
      event.dataTransfer.setData(
        "application/reactflow/node",
        JSON.stringify(node)
      );
      event.dataTransfer.effectAllowed = "move";
    };

    if (createdNodeData) {
      return (
        <>
          <Typography variant="h6" color="text.secondary" fontWeight={600}>
            Ziehen Sie den neuen Kompetenzpunkt auf das Board, im ihn zu
            verwenden:
          </Typography>

          <Box
            display="flex"
            justifyContent="center"
            justifyItems="center"
            padding={3}
          >
            <Tooltip title="Auf das Board ziehen">
              <Box
                borderRadius="12px"
                border={1}
                display="flex"
                width="fit-content"
                padding={2}
                draggable
                onDragStart={(e) => onDragStart(e, createdNodeData)}
                position="relative"
                boxShadow="0 2px 0 0 #D0D0D0"
                bgcolor={NodeLabelIconData[createdNodeData.type].primary}
              >
                <ArrowDropDownIcon
                  sx={{
                    position: "absolute",
                    fontSize: 28,
                    top: -12,
                    width: "100%",
                    left: 0,
                    color: "var(--Basic-Grey-Medium-2, #79747E)",
                  }}
                />

                <CircleIcon
                  sx={{
                    position: "absolute",
                    fontSize: 10,
                    bottom: -5,
                    width: "100%",
                    left: 0,
                    color: "var(--Basic-Grey-Medium-2, #79747E)",
                  }}
                />

                <Add sx={{ marginRight: 1 }} />
                <Typography noWrap>{createdNodeData.title}</Typography>
              </Box>
            </Tooltip>
          </Box>
        </>
      );
    }

    return (
      <>
        <Typography variant="h6" color="text.secondary" fontWeight={600}>
          Bitte definieren Sie den neuen Kompetenzpunkt:
        </Typography>

        <RadioGroup
          value={nodeType}
          onChange={(e) => setNodeType(e.target.value as NodeType)}
        >
          <Grid container padding={2} gap={1}>
            {[
              {
                type: NodeType.Concept,
                label: "Konzept",
              },
              {
                type: NodeType.Principle,
                label: "Prinzip",
              },
              {
                type: NodeType.Procedure,
                label: "Prozess",
              },
            ].map(({ label, type }) => (
              <Grid key={`radio_form_node_type_${type}`} item>
                <FormControlLabel
                  sx={{
                    background: "white",
                    width: "fit-content",
                    paddingRight: "10px",
                    borderRadius: "8px",
                  }}
                  value={type}
                  control={<Radio />}
                  label={
                    <Typography fontWeight={600} color="text.secondary">
                      {label}
                    </Typography>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>

        <Box
          sx={{
            background: "white",
            borderRadius: "14px",
          }}
          padding={3}
        >
          <Typography
            width="fit-content"
            fontWeight={600}
            padding={1}
            sx={{
              background: "#F0F0F0",
              borderRadius: "8px",
            }}
            mb={1}
          >
            Kurzbeschreibung, 300 Zeichen
          </Typography>

          <TextField
            multiline
            fullWidth
            rows={4}
            placeholder="Bitte beschreiben Sie den neuen Komptenzenpunkt"
            variant="standard"
            InputProps={{
              sx: {
                ":before": {
                  borderBottom: "none",
                },
              },
            }}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </Box>

        <Box mt={2}>
          <Grid container gap={1} justifyContent="end">
            <Grid item>
              <Button variant="outlined" onClick={() => onCancel()}>
                Abbrechen
              </Button>
            </Grid>

            <Grid item>
              <Button variant="contained" onClick={() => handleCreateNode()}>
                Neu erstellen
              </Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
);

const NodeResultSection = observer(
  ({
    inGraph,
    outGraph,
  }: {
    inGraph: Node<CustomNodeData>[];
    outGraph: NodeData[];
  }) => {
    const { nodeStore } = useStore();

    return (
      <>
        <Typography fontWeight={600}>Auf diesem Board</Typography>

        <Box paddingY={2} paddingX={1} overflow="auto" maxHeight="320px">
          {inGraph.length > 0 ? (
            inGraph.map((node) => (
              <InGraphNodeResultComponent key={`src_${node.id}`} node={node} />
            ))
          ) : (
            <Box paddingX={1} paddingY={2} overflow="auto" maxHeight="320px">
              <Box
                display="flex"
                justifyContent="center"
                padding={2}
                sx={{
                  background: "#F0F0F0",
                  borderRadius: 2,
                }}
              >
                <Typography variant="h6">Keine Ergebnisse</Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Typography fontWeight={600}>Auf anderen Boards</Typography>

        <Box paddingX={1} paddingY={2} overflow="auto" maxHeight="320px">
          {outGraph.length > 0 ? (
            outGraph.map((node) => (
              <OutGraphNodeResultComponent key={`nrc_${node.id}`} node={node} />
            ))
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              padding={2}
              sx={{
                background: "#F0F0F0",
                borderRadius: 2,
              }}
            >
              {nodeStore.loading ? (
                <Box padding={2}>
                  <CircularProgress />
                </Box>
              ) : (
                <Typography variant="h6">Keine Ergebnisse</Typography>
              )}
            </Box>
          )}
        </Box>
      </>
    );
  }
);

const OutGraphNodeResultComponent = observer(({ node }: { node: NodeData }) => {
  const { boardStore } = useStore();
  const { selectedBoard } = boardStore;
  const onDragStart = (event: any, node: NodeData) => {
    event.dataTransfer.setData(
      "application/reactflow/node",
      JSON.stringify(node)
    );
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <Grid
      container
      padding={2}
      marginY={1}
      width="100%"
      sx={{ background: "#fff", borderRadius: 2 }}
    >
      <Grid item xs={12}>
        <Tooltip title="Auf das Board ziehen">
          <Box
            borderRadius="12px"
            border={1}
            display="flex"
            width="fit-content"
            padding={2}
            draggable={!selectedBoard?.readonly}
            onDragStart={(e) => onDragStart(e, node)}
            position="relative"
            sx={{ cursor: selectedBoard?.readonly ? "not-allowed" : "grab" }}
            boxShadow="0 2px 0 0 #D0D0D0"
          >
            <ArrowDropDownIcon
              sx={{
                position: "absolute",
                fontSize: 28,
                top: -12,
                width: "100%",
                left: 0,
                color: "var(--Basic-Grey-Medium-2, #79747E)",
              }}
            />

            <CircleIcon
              sx={{
                position: "absolute",
                fontSize: 10,
                bottom: -5,
                width: "100%",
                left: 0,
                color: "var(--Basic-Grey-Medium-2, #79747E)",
              }}
            />

            <Add sx={{ marginRight: 1 }} />
            <Typography noWrap>{node.title}</Typography>
          </Box>
        </Tooltip>
      </Grid>

      <Grid item xs={12} paddingTop={2}>
        <Typography noWrap>{node.shortDescription}</Typography>
      </Grid>
    </Grid>
  );
});

const InGraphNodeResultComponent = observer(
  ({ node }: { node: Node<CustomNodeData> }) => {
    const reactflow = useReactFlow();

    const fitToNode = () => {
      reactflow.fitView({
        nodes: [node],
        duration: 500,
      });
    };

    const background = NodeLabelIconData[node.data.type].primary;

    return (
      <Tooltip title={node.data.label} onClick={fitToNode}>
        <Grid
          container
          paddingX={2}
          paddingY={1}
          marginY={1}
          width="fit-content"
          sx={{
            background: background,
            borderRadius: 2,
            color: "#fff",
            cursor: "pointer",
          }}
          maxWidth="100%"
        >
          <Grid item xs width="100%">
            <Typography noWrap sx={{ userSelect: "none" }}>
              {node.data.label}
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>
    );
  }
);

const SearchInputComponent = observer(
  ({
    setValue,
    value,
  }: {
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
  }) => {
    return (
      <Input
        fullWidth
        disableUnderline
        startAdornment={<Search />}
        endAdornment={
          <ClearOutlined
            sx={{
              display: value.length > 0 ? "block" : "none",
              cursor: "pointer",
              transition: "all 100ms",
              "&:hover": {
                color: "#aaa",
              },
            }}
            onClick={() => setValue("")}
          />
        }
        sx={{
          background: "#fff",
          padding: "10px 20px",
          borderRadius: 999,
        }}
        componentsProps={{
          input: {
            style: {
              paddingLeft: "10px",
              paddingRight: "10px",
            },
          },
        }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  }
);
