import {
  ArrowBackOutlined,
  ArrowForwardOutlined,
  Close,
  DashboardCustomizeOutlined,
  FlipToFront,
  LabelOutlined,
  QuestionMark,
  Settings,
  SupervisorAccount,
} from "@mui/icons-material";
import { Avatar, IconButton, Menu } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import {
  createTheme,
  CSSObject,
  styled,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Board } from "../models/board";
import { router } from "../router/Routes";
import { useStore } from "../stores/store";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-evenly",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const OpenedBoardsListView = observer(() => {
  const { boardStore } = useStore();

  return (
    <>
      <Box
        sx={{
          padding: "6px 22px",
        }}
      >
        <Typography color="text.secondary" fontWeight={600}>
          {boardStore.openedBoards.length === 0
            ? "Keine geöffneten Boards"
            : "Geöffneten Boards"}
        </Typography>
      </Box>

      <List
        sx={{
          maxHeight: "50%",
          overflowY: "autohide",
        }}
      >
        {boardStore.openedBoards.map((board) => (
          <ListOpenedBoardItem
            key={`li_opened_board_${board.id}`}
            board={board}
          />
        ))}
      </List>
    </>
  );
});

export default observer(function NavBarMui() {
  const navigate = useNavigate();
  const { accountStore } = useStore();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [theme] = useState(() =>
    createTheme({
      palette: {
        mode: "dark",
      },
    })
  );

  const {
    accountStore: { user, logout, isAdmin },
  } = useStore();

  const [open, setOpen] = useState(true);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleJumpToProfile = () => {
    handleClose();
    navigate(`users/${user?.id}`);
  };

  const handleLogout = () => {
    handleClose();
    logout();
  };

  const admin = isAdmin();

  return (
    <Box sx={{ display: "flex" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            {open && (
              <>
                <img src="/logo.png" alt="" width={32} />

                <Typography variant="h6">CoKoMo</Typography>
              </>
            )}

            <IconButton onClick={() => setOpen(!open)}>
              {open ? <ArrowBackOutlined /> : <ArrowForwardOutlined />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <NavItem
              title="Boards"
              route="/boards"
              icon={<DashboardCustomizeOutlined />}
              open={open}
            ></NavItem>
            <NavItem
              title="Kompetenzpunkte"
              route="/nodes"
              icon={<LabelOutlined />}
              open={open}
            ></NavItem>
            <Divider />
          </List>

          {open && <OpenedBoardsListView />}

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <List>
              {admin && <Divider />}
              {admin && (
                <NavItem
                  title="Admin"
                  route="/administration"
                  icon={<SupervisorAccount />}
                  open={open}
                ></NavItem>
              )}
              {admin && (
                <NavItem
                  title="Vorlage"
                  route="/draftOrgaSync"
                  icon={<FlipToFront />}
                  open={open}
                ></NavItem>
              )}

              <div
                onClick={() =>
                  window.open("https://cokomo-it.de/docs/", "_blank")
                }
              >
                <NavItem
                  title="Hilfe"
                  route=""
                  icon={<QuestionMark />}
                  open={open}
                />
              </div>
            </List>

            <Divider />

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                padding: "12px",
              }}
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  src={accountStore.user?.image}
                  sx={{
                    width: "32px",
                    height: "32px",
                  }}
                />

                {open && (
                  <>
                    <Box sx={{ width: "10px" }} />

                    <Typography>
                      {accountStore.user?.displayName ??
                        accountStore.user?.username}
                    </Typography>
                  </>
                )}
              </Box>

              {open && (
                <div onClick={handleMenu}>
                  <Settings />
                </div>
              )}

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleJumpToProfile}>Profil</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          </div>
        </Drawer>
      </ThemeProvider>
    </Box>
  );
});

interface NavParams {
  title: string;
  route: string;
  icon: any;
  open: boolean;
}

function NavItem({ title, route, icon, open }: NavParams) {
  const { modalStore } = useStore();

  return (
    <ListItem key={`li_${title}`} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        disabled={modalStore.modal.open}
        onClick={() => router.navigate(route)}
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          color: {},
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Typography sx={{ color: "text.secondary" }}>{title}</Typography>
          }
          sx={{ opacity: open ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
}

function ListOpenedBoardItem({ board }: { board: Board }) {
  const { modalStore, boardStore } = useStore();
  const title = board.title;

  return (
    <ListItem key={`li_${title}`} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        disabled={modalStore.modal.open}
        onClick={() => router.navigate(`/graphEditor/${board.id}`)}
        sx={{
          minHeight: 48,
          px: 2.5,
          color: {},
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Box flex={1}>
            <ListItemText
              disableTypography
              sx={{
                maxWidth: 150,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              primary={
                <Typography sx={{ color: "text.secondary" }}>
                  {title}
                </Typography>
              }
            />
          </Box>

          {/* Remove Button */}
          <Box
            sx={{
              borderRadius: 999,
              "&:hover": {
                background: "#404040",
              },
            }}
            flex={0}
            onClick={() => boardStore.remOpenedBoard(board.id)}
            width={32}
            alignItems="center"
            justifyContent="center"
            display="flex"
            padding="5px"
          >
            <Close />
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
