import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { unstable_usePrompt } from "react-router-dom";
import { toast } from "react-toastify";
import ReactFlow, {
  addEdge,
  Background,
  Connection,
  Controls,
  Edge,
  EdgeTypes,
  Node,
  NodeDragHandler,
  NodeTypes,
  OnSelectionChangeParams,
  ReactFlowProvider,
  Node as rfNode,
  useEdgesState,
  useNodesState,
  XYPosition,
} from "reactflow";
import { v4 as uuid } from "uuid";

import { Box, CircularProgress } from "@mui/material";

import { useStore } from "../../app/stores/store";

import { EdgeData } from "../../app/models/edgeData";
import CustomEdge from "./customs/CustomEdge";

import "reactflow/dist/style.css";
import { NodeData } from "../../app/models/nodeData";
import { NodeEdgeInfo } from "../../app/models/nodeEdgeInfo";
import CustomNode, { CustomNodeData } from "./customs/CustomNode";
import GraphEditorDetails from "./GraphEditorDetails";
import GraphEditorTools from "./GraphEditorTools";
import "./staticReactFlowEditor.css";

const reactFlowEdgeTypes = {
  default: CustomEdge,
} as EdgeTypes;

const reactFlowNodeTypes = {
  default: CustomNode,
} as NodeTypes;

export default observer(function StaticReactFlowEditor(): any {
  const { modalStore, graphStore, nodeStore, boardStore } = useStore();

  const {
    loadGraphData,
    getNodesForStaticReactFlow,
    getEdgesForStaticReactFlow,
    saveGraphDataFromStaticReactFlow,
    loading,
    edgeIdsToBeDeleted,
    resetEdgeIdsToBeDeleted,
    addEdgeIdToBeDeleted,
    selectedNode,
    selectedEdge,
  } = graphStore;
  const { selectedBoardId, selectedBoard, loadBoard } = boardStore;

  const reactFlowWrapper: any = useRef(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(
    getNodesForStaticReactFlow
  );

  const [edges, setEdges, onEdgesChange] = useEdgesState(
    getEdgesForStaticReactFlow
  );

  const reactNodes = useMemo(
    () =>
      nodes.map((value) => ({
        ...value,
        deletable: !selectedBoard?.readonly,
        style: {
          borderRadius: "9px",
        },
      })),
    [nodes, selectedBoard?.readonly]
  );

  const reactEdges = useMemo(
    () =>
      edges.map((value) => ({
        ...value,
        deletable: !selectedBoard?.readonly,
        focusable: !selectedBoard?.readonly,
        ...(selectedBoard?.readonly
          ? {
              selected: false,
            }
          : {}),
      })),
    [edges, selectedBoard?.readonly]
  );

  const [reactFlowInstance, setReactFlowInstance]: any = useState(null);

  const [displayedBoardId, setDisplayedBoardId] = useState("");

  useEffect(() => {
    graphStore.setIsDirty(false);
  }, [graphStore]);

  useEffect(() => {
    if (selectedBoardId && !loading) {
      if (selectedBoardId !== displayedBoardId) {
        loadBoard(selectedBoardId);
        loadGraphData(selectedBoardId);
        setDisplayedBoardId(selectedBoardId);
      }
      setNodes(getNodesForStaticReactFlow);
      setEdges(getEdgesForStaticReactFlow);
    }
    return () => {
      setNodes([]);
      setEdges([]);
      resetEdgeIdsToBeDeleted();
    };
  }, [loading, loadGraphData, resetEdgeIdsToBeDeleted, setNodes, setEdges, displayedBoardId, getEdgesForStaticReactFlow, getNodesForStaticReactFlow, selectedBoardId, loadBoard, graphStore]);

  // remind user to save
  useEffect(() => {
    var timeout: NodeJS.Timeout | null = null;
    if (graphStore.isDirty) {
      timeout = setTimeout(() => {
        if (graphStore.isDirty) {
          toast.info("Sie haben bereits einige Zeit lang nicht gespeichert.");
        }
      }, 900000);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphStore.isDirty]);

  // fired when tab or browser is closed
  window.addEventListener("beforeunload", (ev) => {
    if (graphStore.isDirty) {
      ev.preventDefault();
      return (ev.returnValue = "Änderungen verwerfen?");
    }
  });

  //fired when user navigates elsewhere
  unstable_usePrompt({
    when: graphStore.isDirty,
    message: "Änderungen verwerfen?",
  }); //TODO: react router yet did not bring back a usePrompt entirely, over the time of programming check if this changes, see: https://github.com/remix-run/react-router/issues/8139

  const onDropNewNode = (event: any) => {
    event.preventDefault();

    const position = getNewNodePosition(event);

    try {
      const node = JSON.parse(
        event.dataTransfer.getData("application/reactflow/node")
      ) as NodeData;

      if (node?.id) {
        handleAddBoardNode(
          node.id,
          node.title,
          node.shortDescription,
          node.type,
          position,
          node.edges
        );
        return;
      }
    } catch (error) {}
  };

  const getNewNodePosition = (event: any) => {
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();

    const position: XYPosition = reactFlowInstance?.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    return position;
  };

  const handleAddBoardNode = async (
    nodeId: string | null,
    title: string,
    shortDescr: string,
    nodeType: string,
    position: XYPosition,
    edges: NodeEdgeInfo[]
  ) => {
    if (selectedBoard?.readonly) return;

    if (!title || title.length === 0) return;
    if (!nodeType) return;

    if (!nodeId) nodeId = await createNewNode(title, shortDescr, nodeType);

    if (nodeId)
      showBoardNode(nodeId, title, shortDescr, nodeType, position, edges);
  };

  const createNewNode = async (
    title: string,
    shortDescr: string,
    nodeType: string
  ): Promise<string | null> => {
    const newNode = nodeStore.initiateNewNode();
    const nodeId = newNode.id;

    newNode.title = title;
    newNode.shortDescription = shortDescr;
    newNode.type = nodeType;

    try {
      await nodeStore.createNode(newNode);
      return nodeId;
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const showBoardNode = (
    nodeId: string,
    title: string,
    shortDescr: string,
    nodeType: string,
    position: XYPosition,
    edges: NodeEdgeInfo[]
  ) => {
    const newRfNode: rfNode<CustomNodeData> = {
      id: nodeId,
      position,
      data: {
        label: title,
        description: shortDescr,
        type: nodeType,
        edges: edges,
      },
    };

    setNodes((nds) => {
      nds.forEach((n) => (n.selected = false));
      newRfNode.selected = true;
      return nds.concat(newRfNode);
    });

    showBoardNodesEdges(nodeId);
    graphStore.setIsDirty(true);

    modalStore.closeModal();
  };

  const showBoardNodesEdges = async (nodeId: string) => {
    var boardNodeEdges: EdgeData[] = [];

    const allEdges = await nodeStore.getNodesEdges(nodeId);
    if (!allEdges) return;

    const boardNodeIds = reactFlowInstance.getNodes().map((n: any) => n.id);
    //filter the edges where source- and target node are on the board
    boardNodeEdges = allEdges.filter(
      (e) =>
        boardNodeIds.includes(e.sourceNodeId) &&
        boardNodeIds.includes(e.targetNodeId)
    );

    boardNodeEdges.forEach((e) => {
      setEdges((eds) =>
        addEdge(
          {
            id: e.id,
            source: e.sourceNodeId,
            target: e.targetNodeId,
            label: e.type,
            data: e,
          },
          eds
        )
      );
    });
  };

  const onConnect = useCallback(
    (params: Connection) => {
      const id = uuid();

      setEdges((eds) =>
        addEdge(
          {
            ...params,
            label: "?",
            id: id,
            data: {
              id: id,
              sourceNodeId: params.source,
              targetNodeId: params.target,
            },
          },
          eds
        )
      );
      graphStore.setIsDirty(true);
    },
    [setEdges, graphStore]
  );

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const handleSaveGraph = async () => {
    const myNodes = reactFlowInstance.getNodes();
    const myEdges = reactFlowInstance.getEdges();

    await saveGraphDataFromStaticReactFlow(
      myNodes,
      myEdges,
      edgeIdsToBeDeleted,
      selectedBoardId
    )
      .then(resetEdgeIdsToBeDeleted)
      .then(() => graphStore.setIsDirty(false))
      .then(() => toast.success("Gespeichert"));
  };

  const onEdgesDelete = useCallback(
    (edges: Edge[]) => {
      if (localStorage.getItem("didSelectOnlyOneEdge") === "true") {
        //makes sure that edges can only be deleted if they were selected individually, no deletion shall take place as a sideproduct of reactflowNode deletion, also see onSelectionChange
        if (!edgeIdsToBeDeleted.includes(edges[0].id)) {
          addEdgeIdToBeDeleted(edges[0].id);
        }
      }
      graphStore.setIsDirty(true);
    },
    [addEdgeIdToBeDeleted, edgeIdsToBeDeleted, graphStore]
  );

  const onNodesDelete = useCallback(
    (_: Node[]) => {
      graphStore.setIsDirty(true);
    },
    [graphStore]
  );

  const onSelectionChange = useCallback(
    ({ nodes, edges }: OnSelectionChangeParams) => {
      //deal with selection of node to display it on details section
      if (nodes.length === 1) {
        const nodeData = graphStore.transformFromReactFlowDataToGraphData(
          nodes,
          []
        ).nodes[0];
        graphStore.setSelectedNode(nodeData);
      } else {
        graphStore.setSelectedNode(null);
      }
      //deal with edge selection to decide if it would be deleted or hidden
      if (edges.length === 1 && nodes.length === 0) {
        graphStore.setSelectedEdge(edges[0]);
        localStorage.setItem("didSelectOnlyOneEdge", "true");
      } else {
        graphStore.setSelectedEdge(null);
        localStorage.setItem("didSelectOnlyOneEdge", "false");
      }
    },
    [graphStore]
  );

  const onNodeDrag: NodeDragHandler = useCallback(
    (_, __) => {
      graphStore.setIsDirty(true);
    },
    [graphStore]
  );

  const onChangeEdgeType = useCallback(
    (edge: Edge<EdgeData>, type: string) => {
      const newEdge = {
        ...edge,
        label: type,
      };

      setEdges((edges) => edges.filter((e) => e.id !== edge.id));
      setEdges((edges) => addEdge(newEdge, edges));

      graphStore.setSelectedEdge(newEdge);
      graphStore.setIsDirty(true);
    },
    [graphStore, setEdges]
  );

  const onRemoveEdge = useCallback(
    (edge: Edge<EdgeData>) => {
      setEdges((edges) => edges.filter((e) => e.id !== edge.id));
      addEdgeIdToBeDeleted(edge.id);

      graphStore.setSelectedEdge(null);
      graphStore.setIsDirty(true);
    },
    [addEdgeIdToBeDeleted, graphStore, setEdges]
  );

  const showTools = !!selectedNode || !!selectedEdge;

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 800,
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div className="dndflow">
          <ReactFlowProvider>
            <div
              className="reactflow-wrapper"
              ref={reactFlowWrapper}
              style={{ position: "relative" }}
            >
              <ReactFlow
                snapToGrid
                nodes={reactNodes}
                edges={reactEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onNodeDrag={onNodeDrag}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={onDropNewNode}
                onDragOver={onDragOver}
                fitView
                edgeTypes={reactFlowEdgeTypes}
                nodeTypes={reactFlowNodeTypes}
                onEdgesDelete={onEdgesDelete}
                onNodesDelete={onNodesDelete}
                onSelectionChange={onSelectionChange}
                deleteKeyCode={["Backspace", "Delete"]}
                // readonly specific configuration
                nodesDraggable={!selectedBoard?.readonly}
                nodesConnectable={!selectedBoard?.readonly}
              >
                <Controls showInteractive={!selectedBoard?.readonly} />
                <Background />
              </ReactFlow>

              <GraphEditorDetails
                hidden={!showTools}
                onChangeEdgeType={onChangeEdgeType}
                onRemoveEdge={onRemoveEdge}
              />

              <GraphEditorTools
                hidden={showTools}
                onSave={handleSaveGraph}
                handleAddBoardNode={handleAddBoardNode}
              />
            </div>
          </ReactFlowProvider>
        </div>
      )}
    </>
  );
});
