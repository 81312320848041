import { Sort } from "@mui/icons-material";
import { Button, Menu, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";

export default function SortMenu<TSortType>(props: {
  sortType: TSortType;
  setSortType: React.Dispatch<React.SetStateAction<TSortType>>;
  choices: Array<{
    type: TSortType;
    title: string;
  }>;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseFactory = (choice?: TSortType) => {
    return () => {
      choice && props.setSortType(choice);

      setAnchorEl(null);
    };
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Sort />}
        onClick={handleClick}
        sx={{
          borderColor: "#D0D0D0",
          height: "100%",
          color: "#404040",
          borderRadius: "10px",
        }}
      >
        <Typography fontWeight="600">
          Sortierung
          {props.sortType !== "none" &&
            `: ${
              props.choices.find((value) => value.type === props.sortType)
                ?.title
            }`}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onClose={handleCloseFactory()}
      >
        {props.choices.map((value) => (
          <MenuItem
            disabled={value.type === props.sortType}
            onClick={handleCloseFactory(value.type)}
          >
            {value.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
