import { makeAutoObservable, runInAction } from "mobx";
import { v4 as uuid } from 'uuid';

import agent from "../api/agent";
import { Board } from "../models/board";

export default class BoardStore {

  readonly MAX_OPENED_BOARDS = 6;

  boards: Board[] | null = null
  templateBoards: Board[] | null = null

  selectedBoard: Board | null = null

  loading = false;
  editMode = false;
  openedBoards: Board[] = [];

  constructor() {
    makeAutoObservable(this)
  }

  loadBoards = async () => {
    this.setLoading(true)
    this.boards = null

    try {
      const boards = await agent.Boards.list()
      runInAction(async () => {
        this.boards = boards;
      })
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false)
  }

  loadTemplateBoards = async () => {
    this.setLoading(true)
    this.templateBoards = null

    try {
      const templateBoards = await agent.Boards.listTemplate()
      runInAction(async () => {
        this.templateBoards = templateBoards;
      })
    } catch (error) {
      console.error(error);
    }
    this.setLoading(false)
  }

  loadBoard = async (id: string) => {
    this.setLoading(true)
    try {
      const board = await agent.Boards.get(id)
      if (board) {
        runInAction(async () => {
          this.setSelectedBoard(board)
        })
      }
    } catch (error) {
      console.error(error)
    }
    this.setLoading(false)
  }

  update = async (board: Board) => {
    await agent.Boards.update(board)
    runInAction(async () => {
      this.setSelectedBoard(board)
    })
  }

  create = async (board: Board) => {
    await agent.Boards.create(board)
    runInAction(async () => {
      this.setSelectedBoard(board)
    })
  }

  initiateNewBoard = () => {
    const newBoard = new Board(
      {
        id: uuid(),
        title: '',
        description: '',
        isInactive: false,
        modifiedOn: new Date(Date.now()),
        createdOn: new Date(Date.now()),
        modifiedBy: undefined,
        createdBy: undefined,
      }
    )
    return newBoard
  }

  deleteBoard = async (boardId: string) => {
    await agent.Boards.delete(boardId)
    this.boards = this.boards?.filter((b) => b.id !== boardId) || null
    this.clearSelectedBoard()
  }

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    })
  }

  setSelectedBoard = (board: Board) => {
    if (board) {
      runInAction(() => {
        this.selectedBoard = board
        this.addOpenedBoard(board)
      })
    } else this.selectedBoard = null
  }

  clearSelectedBoard = () => {
    runInAction(() => {
      this.selectedBoard = null
    })
  }

  addOpenedBoard = (board: Board) => {
    this.remOpenedBoard(board.id)
    this.openedBoards.unshift(board)

    if (this.openedBoards.length > this.MAX_OPENED_BOARDS) {
      this.openedBoards.pop()
    }
  }

  remOpenedBoard = (boardId: string) => {
    this.openedBoards = this.openedBoards.filter(b => b.id != boardId)
  }

  clearOpenedBoards = () => {
    this.openedBoards = []
  }

  get selectedBoardId() {
    if (this.selectedBoard) {
      return this.selectedBoard?.id
    } else return ''
  }

}