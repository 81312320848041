import { useEffect, useState } from "react";

import { Add, Image, ManageSearch } from '@mui/icons-material';
import { Avatar, Button, Collapse, Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, Select, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { Node, XYPosition } from "reactflow";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { ListWithBorders } from "../../app/common/ListWithBorders";
import { NodeEdgeInfo } from "../../app/models/nodeEdgeInfo";
import { useStore } from "../../app/stores/store";
import { useDebounce } from "../../app/common/UtilHooks";

interface Props {
    addNode: (nodeId: string | null, title: string, shortDescr: string, nodeType: string, position: XYPosition, edges: NodeEdgeInfo[]) => void
    position: XYPosition
    graphNodes: Node[]
}

export default observer(function AddNodeModal({ addNode, position, graphNodes }: Props) {

    const { nodeStore } = useStore()
    const { nodeTypes } = nodeStore
    const { loadNodes, clearNodes, nodes, selectedNode, setSelectedNodeById, clearSelectedNode, loading } = nodeStore

    const [title, setTitle] = useState('')
    const debouncedTitleForSearch = useDebounce(title, 500)

    const [shortDescr, setShortDescr] = useState('')
    const [type, setType] = useState('')

    useEffect(() => {
        clearNodes()
        clearSelectedNode()
        return () => {
            clearNodes()
            clearSelectedNode()
        }
    }, [clearNodes, clearSelectedNode])

    useEffect(() => {
        if (debouncedTitleForSearch.length > 3) {
            loadNodes(debouncedTitleForSearch)
        }
    }, [debouncedTitleForSearch, loadNodes])

    const handleListItemClick = (id: string,) => {
        if (selectedNode && selectedNode.id === id) clearSelectedNode()
        else setSelectedNodeById(id);
    };

    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <Typography variant="h4">Kompetenbasis hinzufügen/einblenden</Typography>
            </Grid>
            <Grid item>
                <TextField sx={{ width: '100%' }} label='Titel/Suche (mind. 4 Zeichen eingeben)' onChange={(e) => setTitle(e.target.value)}>{title}</TextField>
            </Grid>
            <Grid item container spacing={2} justifyContent='space-between'>
                <Grid item xs={5}>
                    <Stack spacing={2}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <Add fontSize="large" color="success"></Add>
                            <Typography variant="h5">Neue Kompetenzbasis erstellen</Typography>
                        </Stack>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={type}
                                label="Typ"
                                onChange={(e) => setType(e.target.value as string)}
                            >
                                {nodeTypes.map(opt => {
                                    return <MenuItem key={opt.title} value={opt.title}>{opt.title}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <TextField label='Beschreibung (optional)' multiline rows={5} onChange={(e) => setShortDescr(e.target.value)}>{shortDescr}</TextField>
                        <Button color="success" disabled={title.length < 4 || type.length === 0} variant="outlined" onClick={() => addNode(null, title, shortDescr, type, position, [])}>Kompetenbasis erstellen und einblenden</Button>
                    </Stack>
                </Grid>
                <Grid item>
                    <Divider orientation="vertical" />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Stack spacing={2}>
                            <Stack direction='row' alignItems='center' spacing={2}>
                                <ManageSearch fontSize="large" color="primary"></ManageSearch>
                                <Typography variant="h5">Bestehende Kompetenzbasis einblenden</Typography>
                            </Stack>
                            {
                                loading ?
                                    <LoadingNodesSkeleton />
                                    :
                                    <ListWithBorders height={400} >
                                        {nodes ? nodes.map((n) => {
                                            const nodeIsAlreadyOnBoard = graphNodes.map(n => n.id).includes(n.id)
                                            return <>
                                                <ListItemButton disabled={nodeIsAlreadyOnBoard} selected={n.id === selectedNode?.id} key={'listitem_' + n.id} onClick={() => handleListItemClick(n.id)} divider>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <Image />
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={n.title} secondary={nodeIsAlreadyOnBoard ? "Kompetenzbasis befindet sich bereits auf dem Board" : ""} />
                                                    {n.id === selectedNode?.id ? <ExpandLess /> : <ExpandMore />}
                                                </ListItemButton>
                                                <Collapse key={'collapse_' + n.id} in={n.id === selectedNode?.id} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding>
                                                        <ListItem sx={{ pl: 4 }}>
                                                            <ListItemText secondary={n.shortDescription} />
                                                        </ListItem>
                                                    </List>
                                                </Collapse>
                                            </>
                                        }) : <ListItem >
                                            <ListItemText primary="Keine Ergebnisse" />
                                        </ListItem>
                                        }
                                    </ListWithBorders>
                            }
                            <Button disabled={selectedNode == null} variant="outlined" onClick={() => { if (selectedNode && selectedNode.id) addNode(selectedNode.id, selectedNode.title, selectedNode.shortDescription, selectedNode.type, position, selectedNode.edges) }}>Einblenden</Button>
                        </Stack>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid >
    );
});

function LoadingNodesSkeleton() {
    return <ListWithBorders height={400} >
        <ListItemButton >
            <Skeleton variant="circular" width={40} height={36} />
            <Skeleton sx={{ ml: 2 }} variant="rectangular" width="100%" height={32} />
        </ListItemButton>
        <ListItemButton >
            <Skeleton variant="circular" width={40} height={36} />
            <Skeleton sx={{ ml: 2 }} variant="rectangular" width="100%" height={32} />
        </ListItemButton>
        <ListItemButton >
            <Skeleton variant="circular" width={40} height={36} />
            <Skeleton sx={{ ml: 2 }} variant="rectangular" width="100%" height={32} />
        </ListItemButton>
        <ListItemButton >
            <Skeleton variant="circular" width={40} height={36} />
            <Skeleton sx={{ ml: 2 }} variant="rectangular" width="100%" height={32} />
        </ListItemButton>
    </ListWithBorders>
}