import { Tooltip } from "@mui/material";
import { CSSProperties } from "react";

import { EdgeProps, getBezierPath, Position } from "reactflow";

import { Observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import "./CustomEdge.css";

const foreignObjectSize = 40;

interface Props extends EdgeProps {
  id: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  sourcePosition: Position;
  targetPosition: Position;
  label: string;
  style: CSSProperties;
}

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
  selected,
  source,
  target,
}: Props) {
  const { graphStore, boardStore } = useStore();

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY: targetY + 5,
    targetPosition,
  });

  const displayLabel = label === "?" ? "..." : label;

  const buttonOffset = getButtonOffset(sourceX, sourceY, targetX, targetY);

  return (
    <Observer>
      {() => {
        const isDisabledEdge =
          graphStore.selectedNode &&
          source !== graphStore.selectedNode.nodeId &&
          target !== graphStore.selectedNode.nodeId;

        const buttonStyle = selected
          ? {
              border: "2px solid black",
            }
          : {
              border: "2px solid #9e9e9e",
            };

        return (
          <>
            <path
              id={id}
              className={"react-flow__edge-path stroke-width-2"}
              d={edgePath}
              style={isDisabledEdge ? { opacity: "0.2" } : {}}
            />
            <foreignObject
              width={foreignObjectSize}
              height={foreignObjectSize}
              x={labelX + buttonOffset.x - foreignObjectSize / 2}
              y={labelY + buttonOffset.y - foreignObjectSize / 2}
              requiredExtensions="http://www.w3.org/1999/xhtml"
              style={isDisabledEdge ? { opacity: "0.2" } : {}}
            >
              <div unselectable="on">
                {!boardStore.selectedBoard?.readonly ? (
                  <Tooltip title="Doppelklick zum Bearbeiten" enterDelay={1000}>
                    <button className="edgebutton" style={buttonStyle}>
                      {displayLabel}
                    </button>
                  </Tooltip>
                ) : (
                  <button className="edgebutton" style={buttonStyle}>
                    {displayLabel}
                  </button>
                )}
              </div>
            </foreignObject>
          </>
        );
      }}
    </Observer>
  );
}

const getButtonOffset = (
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number
): { x: number; y: number } => {
  // fixed offset
  const offset = 15;

  // normal vector of the line which through "source" and "target" 2 points
  const normal = {
    x: targetY - sourceY,
    y: sourceX - targetX,
  };

  // normal vector length
  const normalLength = Math.sqrt(normal.x * normal.x + normal.y * normal.y);

  // button will floating on normal line
  // with offset 15 and bezier curve algorithm will ensure that button will nerver overlap the line
  return {
    x: (normal.x / normalLength) * offset,
    y: (normal.y / normalLength) * offset,
  };
};
