import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Tooltip,
  Typography,
} from "@mui/material";

import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import { observer } from "mobx-react-lite";
import { Edge, useReactFlow } from "reactflow";
import { EdgeTypeIcon } from "../../app/common/Icons";
import LabelNotice from "../../app/common/LabelNotice";
import { EdgeData } from "../../app/models/edgeData";
import { GraphNodeData } from "../../app/models/graphData";
import { NodeEdgeInfo } from "../../app/models/nodeEdgeInfo";
import { router } from "../../app/router/Routes";
import { useStore } from "../../app/stores/store";
import { NodeLabelIconData } from "../nodes/NodeCardLabelIcon";

export default observer(function GraphEditorDetails({
  hidden,
  onChangeEdgeType,
  onRemoveEdge,
}: {
  hidden?: boolean;
  onChangeEdgeType: (edge: Edge<EdgeData>, type: string) => void;
  onRemoveEdge: (edge: Edge<EdgeData>) => void;
}) {
  const { graphStore } = useStore();
  const { selectedNode, selectedEdge } = graphStore;

  return (
    (selectedNode || selectedEdge) && (
      <Box
        display={hidden ? "none" : "block"}
        position="absolute"
        top={20}
        right={20}
        minWidth={400}
        maxWidth={500}
        width="30%"
      >
        <Card
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: "20px",
            border: "1px solid #D0D0D0",
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              padding: "0 !important",
              overflow: "auto",
              maxHeight: "90vh",
            }}
          >
            <Box paddingX={2} paddingY={4}>
              {selectedNode && <NodeDetails node={selectedNode} />}
              {selectedEdge && (
                <EdgeDetails
                  edge={selectedEdge}
                  onChangeEdgeType={onChangeEdgeType}
                  onRemoveEdge={onRemoveEdge}
                />
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    )
  );
});

const EdgeDetails = observer(
  ({
    edge,
    onChangeEdgeType,
    onRemoveEdge,
  }: {
    edge: Edge<EdgeData>;
    onChangeEdgeType: (edge: Edge<EdgeData>, type: string) => void;
    onRemoveEdge: (edge: Edge<EdgeData>) => void;
  }) => {
    const {
      graphStore,
      boardStore: { selectedBoard },
    } = useStore();

    return (
      <Box paddingX={2}>
        <Typography variant="h6" noWrap>
          Verbindungstyp
        </Typography>

        <Box py={2}>
          {graphStore.edgeTypes.map((edgeType) => {
            const isSelected = edgeType.abbreviation === edge.label;
            const color = isSelected ? "#404040" : "#A0A0A0";
            const fontWeight = isSelected ? 600 : 400;

            return (
              <Button
                key={`edge_type_${edgeType.id}`}
                fullWidth
                sx={{ justifyContent: "start" }}
                size="large"
                startIcon={
                  <EdgeTypeIcon variant={edgeType.abbreviation} color={color} />
                }
                onClick={() => onChangeEdgeType(edge, edgeType.abbreviation)}
              >
                <Typography color={color} fontWeight={fontWeight}>
                  {edgeType.title}
                </Typography>
              </Button>
            );
          })}
        </Box>

        <Divider />

        <Box pt={2}>
          <Tooltip title="Auf das Board ziehen">
            <Button
              fullWidth
              color="error"
              variant="outlined"
              sx={{
                paddingY: 2,
              }}
              disableRipple
              disabled={selectedBoard?.readonly}
              onClick={() => onRemoveEdge(edge)}
            >
              <Typography
                fontWeight={600}
                color={selectedBoard?.readonly ? "inherit" : "error"}
              >
                Edge löschen
              </Typography>
            </Button>
          </Tooltip>
        </Box>
      </Box>
    );
  }
);

const NodeDetails = observer(({ node }: { node: GraphNodeData }) => {
  return (
    <>
      <Typography variant="h5" noWrap>
        {node.title}
      </Typography>

      <Box paddingX={2}>
        <Box paddingY={2}>
          <LabelNotice
            color={NodeLabelIconData[node.type].primary}
            text={node.type}
          />
        </Box>

        <Typography color="GrayText">{node.description}</Typography>

        <Box display="flex" justifyContent="end" paddingY={2}>
          <Button
            startIcon={<FileOpenOutlinedIcon />}
            onClick={() => router.navigate(`/nodes/${node.nodeId}`)}
          >
            Detail öffnen
          </Button>
        </Box>

        <Typography fontWeight={600}>Eingehende Pfeile</Typography>

        <Box paddingY={2}>
          {node.edges &&
            node.edges
              .filter((e) => e.direction === "from")
              .map((e) => <EdgeNode info={e} key={`from_${e.edgeId}`} />)}
        </Box>

        <Typography fontWeight={600}>Ausgehende Pfeile</Typography>

        <Box paddingY={2}>
          {node.edges &&
            node.edges
              .filter((e) => e.direction === "to")
              .map((e) => <EdgeNode info={e} key={`to_${e.edgeId}`} />)}
        </Box>
      </Box>
    </>
  );
});

function EdgeNode({ info }: { info: NodeEdgeInfo }) {
  const reactflow = useReactFlow();

  const fitToNode = () => {
    const graphNodes = reactflow.getNodes().filter((n) => n.id === info.nodeId);

    reactflow.fitView({
      nodes: graphNodes,
      duration: 500,
    });
  };

  return (
    <Box
      paddingX={2}
      paddingY={1}
      marginY={1}
      sx={{ background: "#F0F0F0", borderRadius: 2, cursor: "pointer" }}
      onClick={fitToNode}
    >
      <LabelNotice
        color={NodeLabelIconData[info.nodeType].primary}
        text={info.nodeTitle}
      />
    </Box>
  );
}
